/* 
	BHA Landing Page  CSS
*/


/* General Styles */

body {
    font-family: "Raleway", Arial, sans-serif;
    font-weight: 400;
    color: #555;
}

h1,
h2,
h3,
h4,
h5 {
    word-wrap: break-word;
}

h2 {
    line-height: 46px;
}

img {
    max-width: 100%;
    height: auto !important;
}

h1 {
    letter-spacing: -2px;
}

h2 {
    letter-spacing: -1px;
}


/* Common classes */

.block-padding {
    padding-top: 66px;
    padding-bottom: 62px;
}

.block-padding-sm {
    padding-top: 28px;
    padding-bottom: 48px;
}

.block-padding-xs {
    padding-top: 20px;
    padding-bottom: 20px;
}

.block-padding-custom {
    padding: 1px 0 20px 0
}

.font-weight-normal {
    font-weight: normal !important
}

.font-weight-semibold {
    font-weight: 600;
}

.font-weight-bold {
    font-weight: bold;
}

.text-color-blue {
    color: #5e869f;
}

.text-color-brownish {
    color: #bca99d;
}

.text-color-brownish-light {
    color: #d0c5bd;
}

.text-color-darkgray {
    color: #3d3935;
}

.text-color-lightgray {
    color: #75716c;
}

.text-uppercase {
    text-transform: uppercase;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-28 {
    margin-top: 28px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-45 {
    margin-top: 45px;
}

.margin-top-58 {
    margin-top: 58px;
}

.margin-bottom-70 {
    margin-bottom: 70px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-22 {
    font-size: 22px;
    line-height: 41px;
    color: #75716c;
}

.font-size-29 {
    font-size: 29px;
}

.font-size-39 {
    font-size: 39px;
}

.font-size-41 {
    font-size: 41px;
    line-height: normal;
}

.font-size-49 {
    font-size: 49px;
}

.line-height-14 {
    line-height: 12px;
}

.line-height-22 {
    line-height: 22px;
}

.line-height-32 {
    line-height: 32px;
}

.line-height-37 {
    line-height: 37px;
}

.bgcolor-gray {
    background-color: #f2f2f2;
}

.bgcolor-darkgray {
    background-color: #dfdede;
}

.bgcolor-white {
    background-color: #fff;
}

.img-alignleft {
    float: left;
    margin: 0 30px 30px 0;
}

.padding-right-40 {
    padding-right: 65px;
}

.padding-right-25 {
    padding-right: 25px;
}

.padding-left-40 {
    padding-left: 65px;
}

.padding-left-30 {
    padding-left: 30px;
}

.padding-top-22 {
    padding-top: 22px;
}

.padding-lr-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.valign-middle {
    vertical-align: middle;
}

.clear {
    clear: both;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.no-padding-left {
    padding-left: 0
}

.no-padding-right {
    padding-right: 0
}

.p-relative {
    position: relative;
}

.btn-primary-custom {
    padding: 7px 55px;
    font-size: 26px;
    border-radius: 33px;
    border-color: #5587a2;
    background-color: #5587a2;
    color: #fff;
}

.loader {
    color: #286090;
    font-size: 84px;
}

.p-comment {
    font-size: 18px;
    letter-spacing: .05em;
    line-height: 32px;
}

.btn-primary-custom:active,
.btn-primary-custom:visited,
.btn-primary-custom:hover {
    color: #fff;
}

.modal-backdrop {
    z-index: -1 !important;
}


/* Basic Structure */

#site-header,
.site-banner,
#content {
    float: left;
    width: 100%;
    position: relative;
    clear: both;
}


/* Header Styles */

#site-header {
    background: #fff;
    z-index: 999;
}

body.optin-page #site-header {
    padding-top: 35px;
}

#site-header h1.site-title {
    text-align: center;
    font-size: 34px;
    font-weight: 300;
    color: #3d3935;
    margin-bottom: 0;
}

#site-header h2.site-byline {
    text-transform: uppercase;
    text-align: center;
    margin-top: 1px;
    color: #cbae9a;
    font-weight: 600;
    font-size: 18px;
    line-height: normal;
}

.header-logo:hover {
    text-decoration: none;
}


/* Site Banner */

.site-banner {
    background: #f1f1f1;
}

.left-container,
.right-container {
    height: 550px;
}

.right-container img {
    position: relative;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.banner-text {
    position: relative;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.banner-text h2 {
    font-size: 62px;
    line-height: 65px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    color: #3d3935;
    margin-bottom: 42px;
}

.btn-primary-medium,
.banner-text .btn-primary {
    margin: 0 auto;
    background-color: #5587a2;
    border-width: 0;
    border-radius: 500px;
    font-size: 30px;
    line-height: normal;
    font-family: 'Lato', Arial, sans-serif;
    padding: 8px 70px 10px;
}

.landing-page .section1 .btn:hover {
    background-color: #33647f;
}

.icon-wrap p span {
    display: block;
}

.second-section h2 {
    font-size: 48px;
    text-align: center;
    color: #3d3935;
    line-height: 52px;
    text-transform: uppercase;
}

.second-section .step-container {
    margin-top: 60px;
}

.second-section .step {
    text-align: center;
}

.second-section .step .gold-text {
    font-size: 24px;
    color: #75716c;
    margin-top: 30px;
}

.second-section .step .black-text {
    font-size: 24px;
    color: #3d3935;
    margin-top: 30px;
    font-weight: 700;
    line-height: 28px;
}

.second-section .btn {
    margin-top: 65px;
}

.second-section .caption-second-section {
    color: #75716c;
    font-size: 21px;
    line-height: 40px;
    text-align: center;
    margin-top: 70px;
}

.second-section .credits-second-section {
    color: #75716c;
    font-size: 21px;
    text-align: center;
    margin-top: 70px;
}

.share-text {
    font-size: 22px;
    color: #bca99d;
    margin-top: -4px;
}

.result-video {
    text-align: center;
    padding-top: 20px;
}

.tel:hover {
    color: #5e869f;
}

.tel {
    color: inherit;
}

.circle-progress {
    margin: 0px 20px;
    width: 148px;
    height: 148px;
    display: inline-block;
    float: left;
    position: relative;
}

.progressbar-text {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 0px;
    margin: 0px;
    transform: translate(-50%, -50%);
    color: rgb(94, 134, 159);
    font-size: 6rem;
    font-weight: bold;
}


/* Box Bordered */

.box-bordered {
    border: 3px solid #f2f2f2;
}

.box-title {
    font-size: 26px;
    margin: 14px;
}

.box-header {
    position: relative;
    background-color: #f2f2f2;
    padding: 20px;
}

.box-header-content p {
    position: relative;
    top: 47px;
    padding: 0 40px 0 0;
}

.box-content {
    padding: 27px 27px 27px 33px;
}

.box-content-v {
    padding: 20px 0 20px 0;
}

.content-label {
    letter-spacing: .02em;
}

.box-content ul {
    padding-left: 54px;
}

.box-content li {
    line-height: 27px;
    font-size: 16px;
}

.box-bordered.first {
    padding-bottom: 16px;
    height: 659px;
}

.box-bordered.dark {
    border-color: #dfdede;
}

.section-optin-main.new .box-title {
    font-size: 22px;
    font-weight: bold;
    margin: 10px;
}

.section-optin-main.new .circle-progress {
    width: 118px;
    height: 118px;
    margin: 0 20px 0 15px;
}

.section-optin-main.new .box-header-content p {
    padding: 0;
    top: 35px;
}

.section-optin-main.new .complete-result img {
    display: inline-block;
    float: left;
    margin-right: 30px;
}

.section-optin-main.new .complete-result p {
    font-size: 16px;
    position: relative;
    bottom: 4px;
    line-height: 24px;
}

.section-optin-main.new .optin-form .form-fields .checkbox-symbol {
    float: left;
    position: relative;
    bottom: 9px;
}

.section-optin-main.new .optin-form .form-fields .checkbox-field label {
    font-weight: normal !important;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    position: relative;
    top: 10px;
}

.section-optin-main.new .optin-form .form-buttons .btn {
    font-size: 24px;
    padding: 7px 32px 8px;
}

.section-optin-error.new .optin-form {
    padding-top: 31px;
    height: 400px;
}

.assessment_div .section-optin-error.new h1.title {
    top: 150px !important;
}

.support-item img {
    position: relative;
    display: inline;
    margin-right: 20px;
    width: 24px;
}

.support-item {
    margin-bottom: 20px;
}

.support-item p {
    position: relative;
    display: inline;
}

.mind-item {
    padding-left: 20px;
}

.mind-item img {
    position: relative;
    display: inline;
    margin-right: 20px;
}

.mind-item p {
    position: relative;
    display: inline;
}

.mind-item p span {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 100%;
}

.big-image {
    margin: 0 auto;
    width: 72%;
    border: 10px solid white;
}

.sub-heading {
    margin-top: 20px;
    text-align: center;
}

.sub-heading span {
    display: block;
}

.big-image img {
    margin-bottom: -50px;
    z-index: 2000;
    position: relative;
    border: 5px solid white;
}

.action {
    height: 150px;
    z-index: 1000;
}

.action .action-child {
    width: 100%;
    height: 100%;
    padding-top: 30px;
}

.action .action-child a {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 1200px) {
    .site-banner .container {
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (min-width: 992px) {
    .icon-wrap {
        padding-left: 40px;
        padding-right: 40px;
    }
    .icon-wrap p span {
        padding-top: 15px;
    }
}

@media screen and (max-width: 990px) {
    .site-banner .left-container {
        position: relative;
        height: 300px;
    }
    .site-banner .right-container {
        position: relative;
        height: auto;
    }
    .site-banner .left-container .banner-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .right-container img {
        position: relative;
        top: 50%;
        left: 52%;
        margin-top: 2%;
        transform: translate(-50%, -50%);
    }
    .second-section .step-container {
        margin-top: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .banner-text {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .banner-text h2 {
        margin-top: 0;
        font-size: 32px;
        line-height: normal;
        margin-bottom: 7px;
    }
    .btn-primary-medium,
    .banner-text .btn-primary {
        font-size: 22px;
    }
    .site-banner .left-container {
        position: relative;
    }
    .site-banner .right-container {
        position: relative;
    }
    .site-banner .left-container .banner-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .right-container img {
        position: relative;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
        margin-top: 5%;
        width: 300px;
    }
    .site-banner .left-container {
        position: relative;
        height: 290px;
    }
    .site-banner .right-container {
        position: relative;
        height: 300px;
    }
    .second-section .step-container {
        margin-top: 20px;
    }
}

@media screen and (min-width: 768px) {
    .site-banner {
        min-height: 550px;
        height: auto !important;
        height: 666px;
    }
}

@media screen and (max-width: 767px) {
    h2 {
        line-height: normal;
    }
    .site-banner {
        min-height: 400px;
        height: auto !important;
    }
    .font-size-22 {
        font-size: 24px;
        line-height: 32px;
    }
    #site-header h1.site-title {
        font-size: 24px;
    }
    #site-header h2.site-byline {
        font-size: 16px;
    }
    .action .action-child a span {
        display: none;
    }
    .sub-heading span {
        display: inline;
    }
    .big-image {
        width: 80%;
    }
    .result-video iframe {
        width: 80%;
        height: 300px;
    }
    .second-section {
        padding-left: 10px;
        padding-right: 10px;
    }
    .second-section h2 {
        font-size: 30px;
    }
    .second-section .step {
        margin-top: 50px;
    }
    .site-banner .left-container {
        position: relative;
        height: 250px;
    }
    .site-banner .right-container {
        position: relative;
        height: 270px;
    }
    .site-banner .left-container .banner-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .right-container img {
        position: relative;
        top: 50%;
        left: 52%;
        margin-top: 11%;
        transform: translate(-50%, -50%);
        width: 280px;
    }
    .second-section .step-container {
        margin-top: 20px;
    }
    .caption-second-section br {
        display: none;
    }
    p.checkbox-field.second {
        margin-top: -5px !important;
        margin-left: -26px !important;
    }
}

@media screen and (max-width: 480px) {
    .icon-wrap p {
        font-size: 18px;
        line-height: 22px;
    }
    .icon-wrap img {
        max-width: 20%;
    }
    .font-size-41 {
        font-size: 24px;
    }
    .banner-text h2 {
        font-size: 26px;
    }
}

@media screen and (max-width: 375px) {
    .icon-wrap p {
        font-size: 16px;
        line-height: 20px;
    }
    .icon-wrap img {
        max-width: 20%;
    }
    .font-size-41 {
        font-size: 20px;
    }
    .banner-text h2 {
        font-size: 22px;
    }
    .result-video iframe {
        width: 80%;
        height: 200px;
    }
}

@media screen and (max-width: 325px) {
    .icon-wrap p {
        font-size: 14px;
        line-height: 18px;
    }
    .icon-wrap img {
        max-width: 18%;
    }
    .font-size-41 {
        font-size: 18px;
    }
    .banner-text h2 {
        font-size: 20px;
    }
    .result-video iframe {
        width: 80%;
        height: auto;
    }
}


/* Section Optin Main */

.section-optin-main {
    padding: 87px 20px 45px;
    max-width: 1090px;
    margin: 0 auto;
    margin-bottom: 149px;
}

.optin-form {
    padding-top: 31px;
}

.optin-form .form-fields .text-field {
    text-align: center;
}

.optin-form .form-fields .text-field .input-text {
    width: 100%;
    max-width: 478px;
    border: 1px solid #e0d7d1;
    padding: 13px;
    font-size: "Raleway";
    font-size: 21px;
    line-height: normal;
    color: #beb395;
    font-weight: 600;
    margin: 9px 0 10px;
}

.optin-form .form-fields .text-field .input-text.optin-input-email,
.optin-form .form-fields .text-field .input-text.optin-input-name {
    background: url(../images/contact-icon.png) no-repeat;
    background-size: auto 60%;
    background-position: 4.8% 50%;
    padding-left: 85px;
}

.optin-form .form-fields .text-field .input-text.optin-input-email {
    background-image: url(../images/mail-icon.png);
    background-size: auto 48%;
}

.optin-form .form-fields .checkbox-field {
    text-align: center;
    cursor: pointer;
    margin-top: -3px;
}

.optin-form .form-fields .checkbox-field label {
    font-family: "Raleway";
    font-weight: 600;
    color: #b7a99a;
    font-size: 21px;
}

.optin-form .form-fields .checkbox-field .checkbox-symbol {
    background: url(../images/unchecked.png) center center no-repeat;
    vertical-align: middle;
    width: 28px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    height: 28px;
    background-size: 100%;
    margin: 13px 16px 16px 0;
}

.optin-form .form-fields .checkbox-field.checkedbox .checkbox-symbol {
    background-image: url(../images/checked.png);
}

.optin-form .form-fields .checkbox-field.second {
    margin-top: -30px;
    margin-left: -44px;
}

.optin-form .form-buttons {
    text-align: center;
}

.optin-form .form-buttons .btn {
    padding-top: 9px;
    padding-bottom: 12px;
}

@media screen and (max-width: 1090px) {
    .section-optin-main {
        margin-left: 20px;
        margin-right: 20px;
    }
}


/* CHART */

@media (min-width: 768px) {
    .side-label {
        max-width: 60px;
        float: right;
    }
    .side-label p.text {
        line-height: 14px;
        font-size: 12px;
    }
    .side-label p.number {
        line-height: 12px;
    }
    .chart {
        position: relative;
        border-top: 1px solid #f2f2f2;
        margin-top: 44px;
    }
    .chart-lines {
        width: 100%;
        position: absolute;
    }
    .chart-lines .lines {
        border-bottom: 1px solid #f2f2f2;
        height: 22px;
        padding: 0;
        margin: 0;
    }
    .chart-lines .lines.last {
        border-color: #cbcbcb;
    }
    .chart-lines .lines span {
        display: none;
    }
    .chart-box {
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
    }
    .chart-box .chart-bar {
        height: 220px;
    }
    .chart-bar .bars-wrap {
        display: inline-block;
        position: relative;
        height: 100%
    }
    .chart-bar .bars {
        position: absolute;
        bottom: 0;
        width: 100%;
        right: 0;
    }
    .chart-bar .bars div {
        background-color: #5b7e96;
        margin-right: 8px;
        margin-left: 8px;
        height: 100%;
        color: #fff;
        text-align: center;
        font-weight: 600;
        border-radius: 8px 8px 0 0;
        padding-top: 5px;
    }
    .chart-label .bars-wrap {
        height: 48px;
    }
    .clabel {
        color: #fff;
        background-color: #6c8ca2;
        text-transform: uppercase;
        font-size: 10px;
        text-align: center;
        height: 100%;
        border-radius: 0 0 8px 8px;
        padding-top: 10px;
    }
    .clabel .single {
        position: relative;
        top: 6px;
    }
    .chart-label .bars-wrap {
        padding-right: 8px;
        padding-left: 8px;
    }
    .chart-footer .average-label {
        background-color: #f1f3f4;
        text-align: center;
        border-radius: 8px 0 0 0;
        font-size: 12px;
        padding: 6px 4px;
    }
    .chart-footer {
        margin-top: 5px;
    }
    .average-score {
        padding-left: 8px;
        padding-right: 8px;
        background: #f1f3f4;
        border-radius: 0 8px 0 0;
    }
    .score-group {
        padding-top: 11px;
        padding-bottom: 5px;
    }
    .avg-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #5dbd66;
        margin-bottom: 2px;
        margin: 0 auto;
    }
    .avg-down {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #ef4f4f;
        margin-bottom: 2px;
        margin: 0 auto;
    }
    .avg-normal {
        width: 10px;
        height: 10px;
        background: #f6da40;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        margin: 0 auto;
        position: relative;
        bottom: 2px;
    }
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
    }
    .p_1 {
        height: 10%;
        font-size: 14px;
    }
    .p_2 {
        height: 20%;
        font-size: 26px;
    }
    .p_3 {
        height: 30%;
        font-size: 26px;
    }
    .p_4 {
        height: 40%;
        font-size: 26px;
    }
    .p_5 {
        height: 50%;
        font-size: 26px;
    }
    .p_6 {
        height: 60%;
        font-size: 26px;
    }
    .p_7 {
        height: 70%;
        font-size: 26px;
    }
    .p_8 {
        height: 80%;
        font-size: 26px;
    }
    .p_9 {
        height: 90%;
        font-size: 26px;
    }
    .p_10 {
        height: 100%;
        font-size: 26px;
    }
}

@media (max-width: 425px) {
    .box-content-v {
        padding: 20px 5px !important;
    }
    .average-label.text-uppercase {
        padding: 0 !important;
    }
    .average-score .chart-label.seven-cols {
        padding: 0 !important;
    }
    .box-header {
        text-align: center;
    }
    .circle-progress {
        float: none;
    }
    .box-header-content p {
        top: 15px !important;
    }
    .score-group {
        padding: 0 4px !important;
    }
    .progressbar-wrap {
        width: 70% !important;
    }
    div#progressbar {
        padding: 2px 6px;
        height: 18px;
    }
    .percent-wrap li {
        font-size: 12px;
    }
    .percent-wrap .percent {
        width: 22% !important;
    }
    .percent-wrap span.last {
        right: -6px !important;
    }
}

@media (max-width: 767px) {
    .box-content-v {
        margin-top: 40px;
        padding: 20px;
    }
    .p_1 {
        width: 10%;
        font-size: 14px;
    }
    .p_2 {
        width: 20%;
        font-size: 26px;
    }
    .p_3 {
        width: 30%;
        font-size: 26px;
    }
    .p_4 {
        width: 40%;
        font-size: 26px;
    }
    .p_5 {
        width: 50%;
        font-size: 26px;
    }
    .p_6 {
        width: 60%;
        font-size: 26px;
    }
    .p_7 {
        width: 70%;
        font-size: 26px;
    }
    .p_8 {
        width: 80%;
        font-size: 26px;
    }
    .p_9 {
        width: 90%;
        font-size: 26px;
    }
    .p_10 {
        width: 100%;
        font-size: 26px;
    }
    .avg-up {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #5dbd66;
        margin-bottom: 2px;
        margin: 0 auto;
        position: relative;
        top: 2px;
    }
    .avg-down {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #ef4f4f;
        margin-bottom: 2px;
        margin: 0 auto;
        position: relative;
        top: 2px;
    }
    .avg-normal {
        width: 10px;
        height: 10px;
        background: #f6da40;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        margin: 0 auto;
        position: relative;
        bottom: -4px;
    }
    .score-group .avg-up,
    .score-group .avg-down,
    .score-group .avg-normal {
        float: right;
    }
    .score-group {
        margin-bottom: 50px;
        padding: 0 10px;
    }
    .score-group.last {
        margin-bottom: 20px;
    }
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 100%;
        *width: 100%;
    }
    p.lines.first {
        border-left: 1px solid #cbcbcb;
    }
    .chart-bar.seven-cols {
        padding-top: 10px;
    }
    .chart-bar .bars-wrap {
        margin-top: 30px;
    }
    .chart-container {
        padding: 0;
    }
    .chart-bar .bars {
        position: relative;
    }
    .chart-bar .bars-wrap {
        height: 14.285714285714285714285714285714%;
        *height: 14.285714285714285714285714285714%;
    }
    .chart-bar .bars-wrap {
        padding: 0;
    }
    .chart-bar .bars div {
        background-color: #5b7e96;
        width: 100%;
        color: #fff;
        text-align: right;
        font-weight: 600;
        border-radius: 0 8px 8px 0;
        padding-right: 10px;
        height: 40px;
    }
    .clabel {
        font-size: 14px;
        font-weight: 600;
    }
    .chart-label .bars-wrap {
        padding: 0;
    }
    .side-label p {
        display: inline;
    }
    .chart-label .bars-wrap {
        padding-left: 5px;
        line-height: 70px;
        bottom: 5px;
    }
    .chart-label.seven-cols {
        position: absolute;
        top: 0;
        width: 100%;
    }
    .average-score .chart-label.seven-cols {
        padding: 0 10px;
    }
    .chart-container {
        width: 85%;
        float: right;
    }
    .side-label p.text {
        position: absolute;
        top: -41px;
    }
    .side-label p.text.first {
        right: 0;
    }
    .side-label p.text.last {
        left: 0;
    }
    .side-label p.number {
        display: none;
    }
    .chart-lines {
        position: absolute;
        width: 100%;
        height: 500px
    }
    .chart-lines .lines {
        border-right: 1px solid #ccc;
        width: 10%;
        float: left;
        padding: 0;
        margin: 0;
        height: 100%;
        position: relative;
        display: inline-block;
    }
    .lines span {
        position: absolute;
        top: -22px;
        left: -4px;
    }
    .lines span.last {
        float: right;
        position: relative;
        left: 6px;
    }
    .chart-footer {
        float: left;
        width: 15%;
        position: absolute;
        padding-right: 15px;
    }
    .chart-footer>div {
        padding: 0 !important;
        text-align: center;
    }
    .clearfix.mobile {
        display: none;
    }
    .average-label.text-uppercase {
        padding: 0 10px 0 10px;
    }
    .average-group-mobile {
        background: #f1f3f4;
        height: 100%;
        padding-top: 11px;
        border-radius: 0 0 8px 0;
    }
    .average-label div {
        background: #f1f3f4;
        padding-top: 10px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 0 8px 0 0;
    }
    .average-group-mobile .col-sm-1.text-center {
        padding: 0;
    }
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix {
    display: inline-block;
}


/* start commented backslash hack \*/

* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}


/* close commented backslash hack */

.assessment_div #section2 label input,
.assessment_div #section1 label input[type="radio"] {
    display: none;
}

.assessment_div #section2 label,
.assessment_div #section1 label {
    height: 34px;
    border: 2px solid #5587a2;
    border-radius: 200px;
    padding: 2px 10px 3px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    color: #5587a2;
    margin: 26px 15px 0;
    cursor: pointer;
    transition: 0.5s background-color ease;
    width: 160px;
}

.assessment_div #section2 label.label-checked,
.assessment_div #section2 label:hover,
.assessment_div #section1 label.label-checked,
.assessment_div #section1 label:hover {
    background-color: #5587a2;
    color: #fff;
}

.assessment_div #section1 label.label-unknown {
    width: 100%;
    border: none;
    background: transparent none;
    color: #75716c;
    font-size: 20px;
    margin-top: 21px;
}

.assessment_div #section1 label.label-unknown.label-checked {
    color: #5587a2;
    text-decoration: underline;
}

.assessment_div #section1 input[name="age"],
.assessment_div #section2 input[name="height_ft"],
.assessment_div #section2 input[name="height_in"],
.assessment_div #section2 input[name="weight"] {
    border: 2px solid #5587a2;
    border-radius: 5px;
    font-size: 32px;
    line-height: normal;
    width: 200px;
    margin-top: 24px;
    padding: 7px;
}

.assessment_div .section h1 {
    color: #3d3935;
    font-size: 38px;
    line-height: normal;
    font-weight: 600;
}

.assessment_div #section2 label {
    font-size: 18px;
    margin-left: 12px;
    margin-right: 12px;
}

.answers-wrap li {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.assessment_div #section2 label {
    margin-top: 46px;
}

.close-btn {
    top: 29px;
    right: 34px;
    position: absolute;
    z-index: 99999999;
}

.back-btn.manual {
    position: absolute;
    bottom: 11px;
    left: 38px;
    z-index: 99999999;
}

.back-btn.custom {
    display: none;
    position: absolute;
    bottom: 24px;
    left: 38px;
    z-index: 99;
    top: 100%;
    width: 100% !important;
    border-width: 0 !important;
}

.back-btn a,
.back-btn {
    font-size: 18px;
    text-decoration: none;
    color: #302c27;
    font-size: 600;
}

.back-btn a .fa,
.back-btn .fa {
    color: #397895;
    vertical-align: middle;
    font-size: 30px;
    margin-top: -5px;
}

.hello-container {
    position: relative;
    top: -120px;
}

.q-text {
    position: absolute;
    width: 100%;
}

#question.action-box {
    position: relative;
    bottom: 120px;
}

.action-box {
    position: absolute;
    bottom: -120px;
}

.action-circle.active {
    background: #eee3d5;
    cursor: pointer;
}

.action-box .action-circle {
    margin: 0 8px;
}

.action-circle {
    width: 20px;
    height: 20px;
    background: transparent;
    border: 2px solid #eee3d5;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
    bottom: 2px;
    display: inline-block;
    z-index: 99999999999;
}

.assessment_div #section1 input[name="age"] {
    margin: 0 auto;
}

.arrow-continue {
    position: absolute;
    top: 50%;
    right: 30px;
    font-size: 60px;
    cursor: pointer;
    z-index: 9999999999999;
}

.inputGroup {
    width: 200px;
    display: inline-block;
    position: relative;
}

.inputGroup span {
    position: absolute;
    left: 0;
}

.age-error-msg {
    display: block;
    position: relative;
    margin-top: 13px;
}

.btn-assessment {
    border: 2px solid #5587a2;
    background-color: #5587a2;
    border-radius: 200px;
    padding: 0 50px 3px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-size: 28px;
    line-height: normal;
    font-weight: 600;
    color: #fff;
    margin: 26px 15px 0;
    cursor: pointer;
    transition: 0.5s background-color ease;
}

.btn-assessment:hover {
    background-color: transparent;
    color: #5587a2;
}

.answers-wrap .a-answer {
    position: relative !important;
}

.assessment .title {
    position: absolute;
    width: 100%;
}

.assessment .title span {
    display: none;
    font-weight: normal;
}

.assessment .animated {
    display: block;
}

.assessment .title .animated {
    display: block !important;
}

.b-animate .animated {
    display: block !important;
}

.animate.first {
    animation-delay: .4s;
}

.animate.second {
    animation-delay: .6s;
}

.animate.third {
    animation-delay: .8s;
}

.animate.forth {
    animation-delay: 1s;
}

.animate.question-text {
    animation-delay: .1s;
}

.fadeOutDown.animate.button {
    animation-delay: .6s;
}

.animate.button {
    animation-delay: 2s;
}

[class^="error-"] {
    display: none;
}

.progressbar-wrap {
    position: absolute;
    top: 80%;
    bottom: 0;
    margin: 0 auto;
    width: 45%;
    right: 0;
    left: 0;
}

div#progressbar-start,
div#progressbar {
    padding: 4px 10px;
    height: 22px;
    border-radius: 12px;
}

.progressbar-wrap.start {
    top: 120%;
}

.ui-widget.ui-widget-content {
    border: 1px solid #efe3d6;
}

.ui-progressbar-value.ui-corner-left.ui-widget-header {
    position: relative;
    top: 1px;
    border-radius: 8px;
    background-color: #efe3d6;
    border-color: #efe3d6;
}

.percent-wrap ul {
    padding: 0;
}

.percent-wrap span.last {
    float: right;
    position: absolute;
    right: -20px;
}

.percent-wrap .percent {
    display: inline-block;
    width: 24%;
}

.percent-wrap li {
    color: #cbae9a;
    padding-top: 6px;
}

.main-slide ul {
    padding-left: 0;
}

@media screen and (max-width: 768px) {
    .assessment .title {
        padding-left: 35px;
        padding-right: 65px;
    }
    .hello-container .action-box {
        position: relative;
    }
    .arrow-continue {
        top: 120px;
    }
    .medical-disclaimer {
        font-size: 10px;
    }
}

@media screen and (max-width: 479px) {
    .main-slide {
        padding: 15px 20px 35px;
    }
    .main-slide h1 {
        margin-top: 0;
        font-size: 22px !important;
    }
    .assessment_div #section1 label {
        margin: 14px 15px 0 !important;
    }
    .assessment_div #section1 label {
        font-size: 16px !important;
        padding: 4px 10px 3px !important;
    }
    .assessment_div #section2 label {
        right: 0;
        margin: 0 auto;
        line-height: 26px;
        width: 70%;
        margin-top: 10px !important;
        border: 2px solid #5587a2 !important;
        display: block !important;
        font-size: 16px;
    }
    .arrow-continue {
        top: 50% !important;
    }
    #site-header {
        padding-top: 5px;
    }
    .b-animate,
    .question-text {
        font-size: 24px !important;
        padding-left: 25px;
        padding-right: 25px;
    }
    .assessment .title {
        padding-left: 35px;
        padding-right: 65px;
    }
    .assessment .title span {
        font-size: 22px;
    }
    button#get_started {
        font-size: 22px;
        padding: 0 20px 3px;
    }
    .hello-container .action-box {
        position: relative;
    }
    .age-box {
        margin-top: 30px !important;
    }
    .label-unknown {
        font-size: 16px !important;
        padding-right: 40px !important;
        margin-top: 10px !important;
    }
    input#height_ft,
    input#height_in,
    input#weight,
    input#age {
        font-size: 18px !important;
    }
    input#height_ft,
    input#weight,
    input#age {
        margin-top: 0 !important;
    }
    input#height_in {
        margin-top: 18px !important;
    }
    .weight-box {
        margin-top: 25px;
        position: initial;
    }
    .arrow-continue {
        font-size: 45px;
        right: 15px;
    }
    .text-danger {
        font-size: 12px !important;
    }
    .optin-form h3 {
        font-size: 12px;
        line-height: 16px;
    }
    .optin-form h2 {
        font-size: 22px;
        margin-top: 5px !important;
        line-height: 24px;
    }
    .optin-form h1 {
        font-size: 22px !important;
        margin-top: 5px
    }
    .optin-form input#email,
    .optin-form input#name {
        padding: 8px 8px 8px 40px;
        background-size: 20px;
        font-size: 14px !important;
        margin: 0 0 8px;
    }
    .section-optin-main {
        border: none;
        margin: 0;
    }
    .optin-form .form-fields .checkbox-field {
        margin: 0;
    }
    .optin-form .form-fields .checkbox-field .checkbox-symbol {
        margin: 0;
        width: 20px;
        position: relative;
        left: -7px;
    }
    .optin-form .form-buttons {
        text-align: center;
        padding: 5px;
    }
    .subscribe-to-newsletter h4 {
        font-size: 10px !important;
        line-height: 14px !important;
    }
    .optin-form .form-fields .checkbox-field label {
        font-size: 12px !important;
        width: 100%;
    }
    .form-buttons button {
        font-size: 14px !important;
    }
    #question.action-box {
        bottom: 50px;
    }
    .back-btn.manual {
        bottom: 25px;
        left: 25px;
    }
    .back-btn.custom {
        margin-top: -52px;
        left: 25px;
    }
    /* Result page */
    .header-title {
        margin-top: 0px;
    }
    .header-title h1,
    .header-title h3 {
        font-size: 30px;
    }
    .header-title h2 {
        font-size: 24px;
        line-height: 28px;
    }
    .box-content ul {
        padding-left: 30px;
    }
    .box-header-content p {
        padding: 0 !important;
    }
    .section-title h3 {
        font-size: 26px;
    }
    .mind-box {
        padding: 0;
    }
    .mind-item {
        padding-left: 0;
        padding-left: 10%;
    }
    .mind-item p {
        font-size: 16px;
    }
    .mind-item img {
        width: 60px;
    }
    .autoscroll {
        position: relative;
        bottom: 90px;
    }
    .box-bordered.first {
        padding-bottom: 16px;
        height: 700px;
    }
}

@media screen and (max-width: 320px) {
    .optin-form .form-fields .checkbox-field label {
        font-size: 10px !important;
        width: 100%;
    }
    p.checkbox-field.second {
        margin-top: -5px !important;
        margin-left: -21px !important;
    }
    .section-optin-main {
        padding: 45px 20px 45px;
    }
}

.mind-box a p:hover {
    color: #999;
}

.mind-box a:hover {
    text-decoration: none;
}

.text-small {
    font-size: 10px;
    line-height: 15px;
}

.footer,
footer {
    clear: both;
}

.text-sm {
    font-size: 14px;
    line-height: 16px;
}

.footer.optin {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.localization-menu {
    display: block;
    position: absolute;
    right: 50px;
    top: 30px;
    list-style: none;
    z-index: 99;
}

.localization-menu .dropdown-menu {
    min-width: 77px !important;
    left: -40px;
}

.localization-menu .dropdown-menu a {
    margin-left: 10px !important;
}

.assessment_div .floating-controls .float-close-btn {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 1000000000;
    font-size: 24px;
    color: black;
    font-weight: 300;
}

.floating-controls {
    z-index: 9999999;
    position: absolute;
    top: 40px;
    right: 20px;
}